import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css'] 
})

export class SalesOrderComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChildren('selPriduct') selPriduct: any;
  @ViewChildren('inputQty') inputQty: any;


  printError = [];
  errObjArr = {
    custErr: null,
    phoneErr: null,
    advanceErr :null,

 };
 no_transfr: string='';
   sq_inv_no: any;
  sales_settings:any;
  copyLoading: boolean;
  saleRefNo = '';
  sales_agents: any;
  customer:any;
  branchStocksTotal = 0;
  branchStocks: any;
  up: any;
  showbutton: boolean;
  saleqt_date = <any>new Date();
  stkprd_all:any;
  srch_count:any;
  usr_disply_others_branch_data: any;
  private modalRef: NgbModalRef;
  closeResult: string;
  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email : '',
    mobile: '',
    vat_no: '',
    // phone_no: '',
    op_bal: 0,
    brch_id:0,
    usr_type:0,
    is_supplier : false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  isSubmitInProg = false;
  userType: any;
  salesItems = <any>[{
    salesub_prd:<any>'',
    salesub_qty:null,
    base_qty:null,
    salesub_rate:null,
    salesub_unit_id:'',
    salesub_tax_per:null,
    prd_tax_cat_id:null,
    salesub_tax:'',
    item_desc:'',
    Item_descp:'',
    salesub_gd_id:'',
    prod_units: [],
    measurement2:'',
    measurement1:'',
    measurement3:'',
    measurement4:'',
    measurement5:'',
    measurement6:'',
    measurement7:'',
    unit:'',
    // qty: null,
    loadingImg: false,
    rate: null,
    disc_amnt: 0,
    item_disc_type:2,
    excl_vat:1,
    taxpercentage:null,
    itemRate: null,
    grnd_totl: null,
    base_unit_rate: null,
    item_disc: 0,
    item_bill_disc: 0,
    barcode:<any>'',
    stock_qty: 0,
    batch_ex_date:null,
    salesub_tax_amnt:0,
    
  }];
  godown_all:any;
  userdefltGwn:any;
  gd_id:any;
  valErrors = <any>{};
  price_group_id = <any>1;
  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: '%' }
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];
  taxProdCategories: any;
  barcode = null;
  barcodeloadingImg = false;
  totItemprice = <any>0;
  totItempriceWithoutBillDisc = <any>0;
  totItemDisc = <any>0;
  totItemDiscWithoutBillDisc = <any>0;
  totVatAmnt = <any>0;
  totVatAmntWithoutBillDisc = <any>0;
  sales_note = '';

  salesPayType = [
    { id: 2, name: 'Cash' },
    { id: 1, name: 'Credit' },
    { id: 3, name: 'Bank / Card' },
    // { id: 4, name: 'Multi' }
  ];
  payTypes = ['Cash','Credit','Bank / Card'];
  // cust_types = [
  //   { id: 1, name: 'New', disabled: false },
  //   { id: 2, name: 'Registered' }
  // ];
  sale_pay_type=<any>2;
  cashLedgers:any;
  bankLedgers:any;
  valid_till_date:any;
  inv_types = [
    { id: 1, name: 'Tax Invoice' },
    { id: 2, name: 'Simplified Tax Invoice' },
    { id: 3, name: 'Invoice' },
  ];

  shipping_types = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Cargo' },
    { id: 3, name: 'Pickup' },
  ];
  shipping_type = 1;
  qt_no = '';
  inv_no1 = '';
  vat_no = '';
  phone_no ='';
  order_no = '';
  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  zeroType = false;
  purchtypesel: any = 1;
  billDiscount = null;
  // billDiscountTtl = 0;
  
  display_columns = [
    { id: 'descp', name: 'Description', show: false },
    { id: 'barcode', name: 'Barcode', show: false },
    { id: 'item_disc', name: 'Item Discount', show: false },
    { id: 'tax', name: 'Tax %', show: false },
    { id: 'excld_vat', name: 'Exclude VAT', show: false },
    { id: 'item_total', name: 'Item Total', show: false },
    { id: 'vat_amount', name: 'Vat Amount', show: true },
    { id: 'grand_ttl', name: 'Grand Total', show: true },
    { id: 'item_descrpn', name: 'Item Description', show: false },
    { id: 'batch', name: 'Batch', show: true },
   
  ];

  display_columns_order = [
    // { id: 'excld_vat', name: 'Exclude VAT', show: false },
    { id: 'measurments', name: 'Measurments', show: false },
    { id: 'item_descrpn', name: 'Item Description', show: false },
    { id: 'batch', name: 'Batch', show: true },
   
  ];
  tblSettings = {
    'descp':true,
    'barcode':true,
    'item_disc':true,
    'tax':true,
    'excld_vat':true,
    'item_total':true,
    'vat_amount':true,
    'grand_ttl':true,
    'measurments':true,
    'item_descrpn':true,
    'batch':true,
  };
  inv_type: any;
  print_style: any;
  terms: any;
  search_type: any;
  prd_tax_cat_id: any;
  disable_sales_without_stock: any;
  sales_print_name: any = 1;
  sales_duplicate_Item = false;
  cust_id: any;
  sale_acc_ledger_id: any;
  sale_bank_ledger_id:any;
  validationMsg = '';
  pageLoadingImg = false;
  resultobj:any;
  resultobj1: any = {};
  cmpny:any;
  saleQtnData:any;
  is_prew_description:any;
  qr_inv:any;
  previewLoader = false;
  listing = false;
  searchLoadingImg = false;
  list_sales = [];
  curpage: any;
  lastpage: any;
  from: any;
  pgend: boolean;
  pgstart: boolean;
  base_url: string;
  savedTemplate = {
    title_text: 'TAX INVOICE  فاتورة ضريبية فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  footImgUrl: string;
  hide_item_discount : any;
  hide_vat : any;
  cust_balance: 0;
  cust_bal_sign: '';
  delivery_address: any;
  address: any;
   advance_amount: any;
  importLoader: boolean;
  editMessage: boolean;
  button_show: boolean;
  advanc_amount: any;
  convertMessage: boolean;
  loading: boolean;
  discountMessage: boolean;
  proforma_inv: string;
  country_dec: string;
  usr_hide_sale_order_rate: any;
  hide_rate_colmn: boolean;
  so_id: any;
  checkBatch =false;
  checkMeasurment =false;
  batchLoading = false;
  usr_hide_purch_cost: any;
  unit_available_stock:any;
  available_stock:any;
  bs_prate:any;
  unit_bs_prate:any;
  print_so_style: any;
  copyRef: string;
  so_terms: any;
  prod_alias: any;
  cur_lang: string;

  base_qty: any;
  sale_agent: any;
  customer_det: any;
  enable_sale_below_purch: any;
  g_settings: any;
  generalSearch_type: any;
  reference: any;
  usr_block_pre_date_select: any;
  minDate: Date;
  
  constructor(private translate: TranslateService,private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,public router: Router,private ActiveRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.up = this.coreService.getUserPrivilage();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
       this.cur_lang = value;
       this.aliasChecked()
    })
    this.getAllAgents();
    this.apiService.getClientSettingBykey({ key: 'Batch Support' }).subscribe((res) => {
      if (res['data']) {
        this.checkBatch = (res['data']['cs_value']) ? true : false;
      }
    });

    this.apiService.getClientSettingBykey({ key: 'measurements' }).subscribe((res) => {
      if (res['data']) {
        this.checkMeasurment = (res['data']['cs_value']) ? true : false;
      }
      if(this.checkMeasurment == false){

        this.display_columns_order = [
          // { id: 'excld_vat', name: 'Exclude VAT', show: false },
          // { id: 'measurments', name: 'Measurments', show: false },
          { id: 'item_descrpn', name: 'Item Description', show: false },
          { id: 'batch', name: 'Batch', show: true },
         
        ];

      }
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.advance_amount = 0;
    this.base_url = this.apiService.PHP_API_SERVER;
    this.importLoader = false;
    this.editMessage = false;
    this.nextRefNo();
    this.getGodownList();
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.gd_id = this.userdefltGwn ? Number(this.userdefltGwn) : 0;
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.updateSalesTableSettigns();
    this.updateSalesOrderTableSettigns();
    this.getSalesSetting();
    this.getTemplete();
    this.so_id = this.ActiveRoute.snapshot.paramMap.get('sale_order_inv_no');
    if (this.so_id && !isNaN(this.so_id)){
      this.converToSales(this.so_id);
    }
    this.sale_acc_ledger_id = this.coreService.getUserData('usr_cash_ledger_id');
    if(!this.sale_acc_ledger_id){
      this.apiService.getBranchCashAccount().subscribe((led_res) => {
        this.cashLedgers= led_res.data.ledger;
        this.sale_acc_ledger_id = led_res.data.taxled_ledger_id;
      });
    }
    this.cmpny = this.coreService.getUserData('cmpny');
    this.usr_disply_others_branch_data = this.coreService.getUserData('usr_disply_othr_branch_data');
    this.usr_hide_sale_order_rate = this.coreService.getUserData('usr_hide_sale_order_rate');
    
    if(this.usr_hide_sale_order_rate){
      console.log(this.usr_hide_sale_order_rate+'   heiiii');
      
      this.hide_rate_colmn=false;
    }else{
      this.hide_rate_colmn=true;
    }
    this.listQtn(1);
    // this.salesItems.push(this.defualtSalesSub);

    this.button_show=true;

    this.country_dec=this.coreService.setDecimalLength();
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
    }else{
      this.prod_alias =null;
    }
  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.selPriduct.last.focus();
    }, 500);
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2 || this.print_style==6) {

        const imagePath = res.data.sale_order_header_img_path != null ? res.data.sale_order_header_img_path : res.data.prnt_header_img_path;
        const imageFtrPath = res.data.sale_order_footer_img_path != null ? res.data.sale_order_footer_img_path : res.data.prnt_footer_img_path;

        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + imagePath;
        this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + imageFtrPath; 
      } else {
        this.tempImgUrl = '';
        this.footImgUrl = '';
      }
      this.hide_item_discount = res.data.hide_item_discount;
      this.hide_vat = res.data.hide_vat;
    });
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.inv_type = this.sales_settings.ss_inv_type;
        this.print_style = this.sales_settings.print_style;
        this.print_so_style = this.sales_settings.so_print_style;
        this.terms = this.sales_settings.sales_default_terms;
        this.search_type = this.sales_settings.search_type;
        if(this.inv_type==3){
          this.purchtypesel = 2;
          this.prd_tax_cat_id = 3;
          this.selectTaxType(2);
        }
        this.disable_sales_without_stock=this.sales_settings.disable_sales_without_stock;
        this.enable_sale_below_purch = this.sales_settings.enable_sale_below_purch;
        this.sales_print_name=this.sales_settings.sales_print_name;
        this.sales_duplicate_Item=this.sales_settings.sales_duplicate_item;
      } else {
       // this.disable_sales_without_stock=0;
        this.enable_sale_below_purch=0;
        this.inv_type = 1;
        this.sales_print_name=1;
        this.print_style = 1;
        this.terms = '';
        this.search_type=1;
      }
      // this.updatePayment();
    });

  }

  // updatePayment(){
  //   if(this.inv_type == 2){
  //     this.sale_pay_type = 2;
  //   }else{
  //     this.sale_pay_type = 1;
  //   }
  //   // this.changePayType();
  // }
 
  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];

    });

  }
  updateSalesTableSettigns(){
    let tabeleSett = this.coreService.getLocalStorage('sales_order_tds');
    let display_columns = [];
    if(tabeleSett){
      display_columns = tabeleSett;
    } else{
      display_columns = this.display_columns;
    }
   
    display_columns.forEach((val)=>{
      this.tblSettings[val.id] = !val.show;
    });
  
  }

  updateTableSettings(){
    this.coreService.setLocalStorage('sales_order_tds', JSON.stringify(this.display_columns));
    this.updateSalesTableSettigns();
    this.closeModal.nativeElement.click();
  }

  updateSalesOrderTableSettigns(){
    let tabeleSett = this.coreService.getLocalStorage('sales_order_tds2');
    let display_columns_order = [];
    if(tabeleSett){
      display_columns_order = tabeleSett;
    } else{
      display_columns_order = this.display_columns_order;
    }
   
    display_columns_order.forEach((val)=>{
      this.tblSettings[val.id] = !val.show;
    });
   

  
  }

  updateOrderTableSettings(){
    this.coreService.setLocalStorage('sales_order_tds2', JSON.stringify(this.display_columns_order));
    this.updateSalesOrderTableSettigns();
    this.closeModal.nativeElement.click();
  }

  searchCashLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    searchval.append('ledger_grp', '3');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.cashLedgers = res['data'];
    });
  }

  searchBankLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    searchval.append('ledger_grp', '4');
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.bankLedgers = res['data'];
    });
  }

  addNewRow() {

    if(this.validateSelectedGodown()){
      this.salesItems.push({
        salesub_prd:'',
        salesub_qty:null,
        base_qty:null,
        salesub_rate:null,
        salesub_unit_id:'',
        salesub_tax_per:null,
        prd_tax_cat_id:null,
        salesub_tax:'',
        item_desc:'',
        Item_descp:'',
        salesub_gd_id:'',
        prod_units: [],
        measurement2:'',
        measurement1:'',
        measurement3:'',
        measurement4:'',
        measurement5:'',
        measurement6:'',
        measurement7:'',
        unit: '',
        // qty: null,
        loadingImg: false,
        rate: null,
        disc_amnt: 0,
        item_disc_type:null,
        excl_vat:1,
        taxpercentage:null,
        itemRate: null,
        grnd_totl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
        barcode:'',
        stock_qty:0,
        batch_ex_date:null,
        salesub_tax_amnt:0
      });
      setTimeout(() => {
        this.selPriduct.last.focus();
      }, 500);
    }
  }
  remove(index) {
    this.salesItems.splice(index, 1);
    this.sumSaleTotal();
  }
  nextRefNo() {
    let searchval = new FormData();
    this.apiService.getOrderSaleRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res['data'].sale_num;
      this.qt_no = res['data'].sq_branch_qt_no;
    });
  }

  nextRefNo1() {
    let searchval = new FormData();
    this.apiService.getSaleRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res['data'].sale_num;
      this.inv_no1 = res['data'].sq_branch_qt_no;
    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });
  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }

  selectCustomer(cust) {
    if (cust) {
      const searchval = new FormData();
      searchval.append('ledger_id', cust.ledger_id);
      this.apiService.getCustDetails(searchval).subscribe((res) => {
        this.customer_det = res.data;
        if(res.data){
        
          this.cust_balance = this.customer_det.balance.bal_without_sign;
          this.cust_bal_sign = this.customer_det.balance.bal_sign;
        }else{
          this.cust_balance = 0;
          this.cust_bal_sign = '';
        }
      });
      this.vat_no = cust.vat_no;
      this.phone_no =cust.mobile;
      this.address =cust.cust_home_addr;
      this.sale_agent= cust.default_sales_agent;
      if(cust.price_group_id){
        this.price_group_id = cust.price_group_id;
      }
      let date = new Date(this.saleqt_date);
      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    } else{
      this.cust_balance = 0;
      this.cust_bal_sign = '';
      // remove customer after selecting payment type credit, change payment type to cash
      if(this.sale_pay_type == 1){
        this.sale_pay_type = 2;
      }
    }
  }

  searchStkPrd(search: string, i) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append('stk_stat', '1');
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getSalesStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  }
 
  searchStkPrdForEdit(ids) {
    let searchval = new FormData();
    searchval.append("ids",ids);
    searchval.append('stk_stat', '1');
    this.apiService.getStockbyItemByIds(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];
      this.srch_count = this.stkprd_all.length;
    });
  }

  getGodownList() {
    let searchval = new FormData();
    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
    });
  }

  selectedProduct(i, isBracodeSearch =false){
      if(this.salesItems[i].salesub_prd && this.validateSelectedGodown()){
    // if(this.validateSelectedGodown()){
      this.getStockDetails(i, isBracodeSearch);
      this.getBranchWiseStock(i,isBracodeSearch);

    } else{
      
      this.salesItems[i] = {
        salesub_prd:'',
        salesub_qty:null,
        base_qty:null,
        salesub_rate:null,
        salesub_unit_id:'',
        salesub_tax_per:null,
        prd_tax_cat_id:null,
        salesub_tax:'',
        item_desc:'',
        Item_descp:'',
        salesub_gd_id:'',
        prod_units: [],
        measurement2:'',
        measurement1:'',
        measurement3:'',
        measurement4:'',
        measurement5:'',
        measurement6:'',
        measurement7:'',
        unit:'',
        // qty: null,
        loadingImg: false,
        rate: null,
        disc_amnt: 0,
        item_disc_type:null,
        excl_vat:1,
        taxpercentage:null,
        itemRate: null,
        grnd_totl: null,
        base_unit_rate: null,
        item_disc: 0,
        item_bill_disc: 0,
         barcode:'',
         salesub_tax_amnt:0
      };
    }

  }
  getpreviousMesurements(i){
    
    let searchval = new FormData();
    if(this.cust_id){
      searchval.append("prd_id",this.salesItems[i].salesub_prd.prd_id);
      searchval.append("cust_id",this.cust_id.cust_id);

      this.salesItems[i].measurement2 = '';
      this.salesItems[i].measurement1 ='';
      this.salesItems[i].measurement3 = '';
      this.salesItems[i].measurement4 ='';
      this.salesItems[i].measurement5 = '';
      this.salesItems[i].measurement6 ='';
      this.salesItems[i].measurement7 = '';

      // this.barcodeloadingImg = true;
      this.apiService.getMeasure(searchval).subscribe((res) => {
          
        if (res['data']) {

          
          this.salesItems[i].measurement2 = res['data'].measurement2? res['data'].measurement2 :'';
          this.salesItems[i].measurement1 = res['data'].measurement1 ? res['data'].measurement1 :'';
          this.salesItems[i].measurement3 = res['data'].measurement3 ? res['data'].measurement3 :'';
          this.salesItems[i].measurement4 = res['data'].measurement4 ? res['data'].measurement4 :'';
          this.salesItems[i].measurement5 = res['data'].measurement5 ? res['data'].measurement5 :'';
          this.salesItems[i].measurement6 = res['data'].measurement6 ? res['data'].measurement6 :'';
          this.salesItems[i].measurement7 = res['data'].measurement7 ? res['data'].measurement7 :'';
          // this.salesItems[i] = {
          //   salesub_prd:'',
          //   salesub_qty:null,
          //   base_qty:null,
          //   salesub_rate:null,
          //   salesub_unit_id:'',
          //   salesub_tax_per:null,
          //   prd_tax_cat_id:null,
          //   salesub_tax:'',
          //   item_desc:'',
          //   salesub_gd_id:'',
          //   prod_units: [],
          //   measurement2:res['data'].measurement2,
          //   measurement1:res['data'].measurement1,
          //   measurement3:res['data'].measurement3,
          //   measurement4:res['data'].measurement4,
          //   measurement5:res['data'].measurement5,
          //   measurement6:res['data'].measurement6,
          //   measurement7:res['data'].measurement7,
          //   unit:'',
          //   // qty: null,
          //   loadingImg: false,
          //   rate: null,
          //   disc_amnt: 0,
          //   item_disc_type:null,
          //   excl_vat:1,
          //   taxpercentage:null,
          //   itemRate: null,
          //   grnd_totl: null,
          //   base_unit_rate: null,
          //   item_disc: 0,
          //   item_bill_disc: 0,
          //    barcode:'',
          //    salesub_tax_amnt:0
          // };


        }
        else{

          this.salesItems[i].measurement2 = '';
          this.salesItems[i].measurement1 ='';
          this.salesItems[i].measurement3 = '';
          this.salesItems[i].measurement4 ='';
          this.salesItems[i].measurement5 = '';
          this.salesItems[i].measurement6 ='';
          this.salesItems[i].measurement7 = '';

        }

      });
    }
      
  }
  updateTaxPerc(i) {
    const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === this.salesItems[i].prd_tax_cat_id);
    this.salesItems[i].salesub_tax_per = selTax[0].taxcat_tax_per;
    this.calcRates(i);

  }

  searchStkPrdBarcode() {
    if(this.validateBarcode() && this.validateSelectedGodown()){

      let searchval = new FormData();
      searchval.append("barcode", this.barcode);
      this.barcodeloadingImg = true;
      this.apiService.getStockbyBarcodeSales(searchval).subscribe((res) => {
        if (res.data) {
          
          if(!this.sales_duplicate_Item){
            var existArrayInx = this.salesItems.findIndex((x =>
              x.salesub_prd.prd_id === res.data.prd_id &&
              (x.item_desc == '' || x.item_desc == null) &&
              x.salesub_unit_id === res.data.produnit_unit_id &&
              x.rate === res.data.bs_srate
            ));
          }

          if(!this.sales_duplicate_Item && existArrayInx != -1){
            
            this.salesItems[existArrayInx].salesub_qty +=1;
            this.calcRates(existArrayInx);
          } else{
             // do not add new row, if last row not selected any item
            if(this.salesItems[this.salesItems.length-1].salesub_prd){
              this.addNewRow();
            }
            let lastRowIndex = this.salesItems.length-1;
            this.salesItems[lastRowIndex].salesub_prd = res['data'];
            this.selectedProduct(lastRowIndex, true);
          }
        } else {
          this.valErrors.barcode = {msg : 'No product found'}
        }
        this.barcode = '';
        this.barcodeloadingImg = false;
      });
    }
  }
  getBranchWiseStock(i,isBracodeSearch){
    let prd_id = this.salesItems[i].salesub_prd.prd_id;
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
       this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
         this.branchStocks = res['data']['data'];
         this.branchStocksTotal = res['data']['total'];
  
       });

  }
     

  getStockDetails(i, isBracodeSearch) {
    this.salesItems[i].sb_id=0;
    this.salesItems[i].loadingImg = true;
    let prd_id = this.salesItems[i].salesub_prd.prd_id;
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', this.gd_id);
    searchval.append('price_group_id', this.price_group_id);
    this.getpreviousMesurements(i);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      let selProdInfo = res['data'];
      this.salesItems[i].salesub_qty = 1;
      this.salesItems[i].prod_units = selProdInfo.prod_units;
      this.salesItems[i].loadingImg = false;
      this.salesItems[i].prd_tax_cat_id = selProdInfo.prd_tax_cat_id;
      this.salesItems[i].salesub_tax_per = selProdInfo.prd_tax;
      this.salesItems[i].base_unit_rate = selProdInfo.base_unit_rate;
      this.salesItems[i].barcode = selProdInfo.prd_barcode;
      this.salesItems[i].item_disc_type = 1;
      this.salesItems[i].Item_descp = selProdInfo.prd_remarks;
      this.salesItems[i].salesub_gd_id = this.gd_id;
      this.salesItems[i].salesub_prd_id = selProdInfo.prd_id;
      this.salesItems[i].batches = selProdInfo.batches;
      this.salesItems[i].p_rate = selProdInfo.bs_prate;
      this.salesItems[i].prd_location = selProdInfo.pl_location;
     
      this.salesItems[i].unit_available_stock= selProdInfo.stock_qty;
      this.salesItems[i].available_stock= selProdInfo.stock_qty;
      this.bs_prate=selProdInfo.bs_prate;
      this.unit_bs_prate=selProdInfo.bs_prate;
      // salesub_unit_id
      // current stock of product, actully chk on the time of save for accurate result
      this.salesItems[i].stock_qty = selProdInfo.stock_qty;
      this.salesItems[i]. batch_ex_date = null;
     
      // this.selctedProd = res['data'];
      this.salesItems[i].salesub_unit_id = (selProdInfo.prd_default_unit ? selProdInfo.prd_default_unit.unit_id : selProdInfo.prd_base_unit_id);

      if(selProdInfo.batches.length > 0 && this.checkBatch){
        this.salesItems[i].sb_id =selProdInfo.batches[0].sb_id;
        this.salesItems[i]. batch_ex_date = selProdInfo.batches[0].sb_expiry_date;

        if (this.salesItems[i].sb_id) {
          // this.selctedProd.stock_qty = 333
          // getGodownBatchStock(){}
          const searchval = new FormData();
          searchval.append("prd_id", this.salesItems[i].salesub_prd.prd_id);
          searchval.append("gd_id", this.gd_id);
          searchval.append("price_group_id", this.price_group_id);
          searchval.append("batch_id", this.salesItems[i].sb_id);
          this.batchLoading = true;
          this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
            this.batchLoading = false;
    
            console.log("gd details");
            console.log(resp['data']['gdStock']);
    
            // console.log("batch Details");
            // console.log(resp['data']['prod_units']);
    
            // console.log("resp['data']");
            // console.log(resp["data"]);
            // this.salesItems[i].stock_qty = resp['data']['gdStock'].gbs_qty || 0;
            // this.salesItems[i].prod_units =resp['data']['prod_units'];
    
            // this.salesItems[i].unit_available_stock =
            //   resp["data"]["gdStock"].gbs_qty || 0;
            //   this.salesItems[i].stock_qty =
            //   resp["data"]["gdStock"].gbs_qty || 0;  
            // this.selectedUnit(i);

            if(resp['data']['gdStock']){
        this.salesItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty || 0;
        this.salesItems[i].stock_qty =resp['data']['gdStock'].gbs_qty || 0;
         //this.selectedUnit(i);

       }
       else{
        this.salesItems[i].sb_id =0;
        this.salesItems[i].unit_available_stock =  0;
        this.salesItems[i].stock_qty =0;
        // this.selectedUnit(i);
       }
          });
        } 

      }
      // this.salesItems[i].salesub_unit_id = (selProdInfo.prd_default_unit ? selProdInfo.prd_default_unit.unit_display : null);

      // this.barcode = this.selctedProd.prd_barcode;
      this.selectedUnit(i);
      if(!isBracodeSearch){
        setTimeout(() => {
          $('.inputQty').focus();
          $('.inputQty').select();
        }, 500);
      }
    });

  }

  focusRateInput(event){
    $('.inputRate').focus();
    $('.inputRate').select();
    return false;
  }


  buttnclck() {
    $("#popup").slideUp();
    this.showbutton = true;
  }
  buttnclck1() {
    $("#popup").slideDown();
    this.showbutton = false;
  }



  selectedUnit(i) {
    
    console.log("gfgfgfgf");
    console.log(i);

    console.log("this.salesItems[i].salesub_unit_id");
    console.log(this.salesItems[i].salesub_unit_id);
    const prdUnts = this.salesItems[i].prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === this.salesItems[i].salesub_unit_id);

    console.log("prdUnts    rrr");
    console.log(prdUnts[0]);
    this.salesItems[i].rate = (prdUnts[0].sur_unit_rate ? prdUnts[0].sur_unit_rate : prdUnts[0].unit_base_qty * this.salesItems[i].base_unit_rate);
    this.salesItems[i].base_qty = prdUnts[0].unit_base_qty;
    this.salesItems[i].unit_bs_prate= this.bs_prate * this.salesItems[i].base_qty;

    if(this.salesItems[i].sb_id){
      
      const searchval = new FormData();
      searchval.append('prd_id', this.salesItems[i].salesub_prd.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id', this.salesItems[i].sb_id);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

        console.log("gd details1");
            console.log(resp['data']['gdStock']);

      //  this.salesItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty / this.salesItems[i].base_qty || 0;
       // this.selectedUnit(i);

       if(resp['data']['gdStock']){
        // this.salesItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty || 0;
        this.salesItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty / this.salesItems[i].base_qty || 0;
        this.salesItems[i].stock_qty =resp['data']['gdStock'].gbs_qty || 0;
        // this.selectedUnit(i);

       }
       else{
        this.salesItems[i].sb_id =0;
        this.salesItems[i].unit_available_stock =  0;
        this.salesItems[i].stock_qty =0;
        // this.selectedUnit(i);
       }
      });
    }
    else{
      this.salesItems[i].unit_available_stock =  this.salesItems[i].available_stock / this.salesItems[i].base_qty;
    }

    console.log("this.salesItems[i].unit_available_stock");
    console.log(this.salesItems[i].unit_available_stock);
    this.calcRates(i);
  }

  calcRates(i, isBillDiscount=false) {

    if(!isBillDiscount){
      this.billDiscount = 0;
      this.salesItems[i].item_bill_disc = 0;
    }

    let qty = this.salesItems[i].salesub_qty ? this.salesItems[i].salesub_qty : 1;
    let rate = this.salesItems[i].rate ? Number(this.salesItems[i].rate) : 0;
    let itemDisc = this.salesItems[i].disc_amnt ? this.salesItems[i].disc_amnt : 0;

    let purchAmnt = qty * rate;
    
    

    if (this.salesItems[i].item_disc_type != 1) {
      itemDisc = (purchAmnt * itemDisc) / 100;
    }

    let itemDiscWithoutBillDisc = itemDisc;
    itemDisc = Number(itemDisc) + Number(this.salesItems[i].item_bill_disc);
    this.salesItems[i].item_disc = itemDisc;

    
    // let rateWithoutBillDisc = rate;
    let itemTotal = 0;
    let itemTotalWithoutBillDisc = 0;
    let rateWithoutBillDisc = rate;
    //tax zero type
    if(this.zeroType){
      
          this.salesItems[i].salesub_tax_amnt = 0;
       
      this.salesItems[i].salesub_tax_amnt_WithoutBillDisc = 0;
      itemTotal = purchAmnt;
      itemTotalWithoutBillDisc = purchAmnt;
    } else {
      if (this.salesItems[i].excl_vat == 1) {
            this.salesItems[i].salesub_tax_amnt = ((purchAmnt - (itemDisc)) * this.salesItems[i].salesub_tax_per) / 100;
          
          this.salesItems[i].salesub_tax_amnt = ((purchAmnt - (itemDisc)) * this.salesItems[i].salesub_tax_per) / 100;

        this.salesItems[i].salesub_tax_amnt_WithoutBillDisc = ((purchAmnt - (itemDiscWithoutBillDisc)) * this.salesItems[i].salesub_tax_per) / 100;
        itemTotal = purchAmnt + this.salesItems[i].salesub_tax_amnt;
        itemTotalWithoutBillDisc = purchAmnt + this.salesItems[i].salesub_tax_amnt_WithoutBillDisc;

      } else {

      
          this.salesItems[i].salesub_tax_amnt = (purchAmnt - (itemDisc)) - (((purchAmnt - (itemDisc)) * 100) / (this.salesItems[i].salesub_tax_per + 100));
       
        this.salesItems[i].salesub_tax_amnt_WithoutBillDisc = (purchAmnt - (itemDiscWithoutBillDisc)) - (((purchAmnt - (itemDiscWithoutBillDisc)) * 100) / (this.salesItems[i].salesub_tax_per + 100));
        // rateWithoutBillDisc = (((rate - itemDiscWithoutBillDisc) * 100) / (this.salesItems[i].salesub_tax_per + 100));
        rate = (purchAmnt - this.salesItems[i].salesub_tax_amnt) / qty;
        rateWithoutBillDisc = (purchAmnt - this.salesItems[i].salesub_tax_amnt_WithoutBillDisc) / qty;
        // this.itemRate = (this.purchAmnt-this.tax_amnt) / this.qty;
        itemTotal = purchAmnt;
         itemTotalWithoutBillDisc = purchAmnt;
      }

   
    }
    
    this.salesItems[i].salesub_tax = this.salesItems[i].salesub_tax_amnt / qty;
    // let itemDiscWithoutBillDisc = itemDisc;
    this.salesItems[i].item_disc_WithoutBillDisc = itemDiscWithoutBillDisc

    this.salesItems[i].salesub_rate = rate;
    this.salesItems[i].item_total = rate * qty - itemDisc;
    this.salesItems[i].salesub_rate_without_bill_disc = rateWithoutBillDisc;

   this.salesItems[i].grnd_totl = itemTotal - itemDisc;
    this.salesItems[i].grnd_totl_WithoutBillDisc = itemTotalWithoutBillDisc - itemDiscWithoutBillDisc;
  
    this.sumSaleTotal()

  }

  sumSaleTotal() {

    this.totItemprice = 0;
    this.totItempriceWithoutBillDisc = 0;
    this.totItemDisc = 0;
    this.totItemDiscWithoutBillDisc = 0;
    this.totVatAmnt = 0;
    this.totVatAmntWithoutBillDisc = 0;
    // this.billDiscountTtl = 0;

    for (var i = 0; i < this.salesItems.length; i++) {
     
      this.totItemprice = Number(this.totItemprice) + (Number(this.salesItems[i]['salesub_qty']) * Number(this.salesItems[i]['salesub_rate']));
      this.totItempriceWithoutBillDisc = Number(this.totItempriceWithoutBillDisc) + (Number(this.salesItems[i]['salesub_qty']) * Number(this.salesItems[i]['salesub_rate_without_bill_disc']));
     
      this.totItemDisc = Number(this.totItemDisc) + Number(this.salesItems[i]['item_disc']);
      this.totItemDiscWithoutBillDisc = Number(this.totItemDiscWithoutBillDisc) + Number(this.salesItems[i]['item_disc_WithoutBillDisc']);
      this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['salesub_tax_amnt']);
      this.totVatAmntWithoutBillDisc = Number(this.totVatAmntWithoutBillDisc) + Number(this.salesItems[i]['salesub_tax_amnt_WithoutBillDisc']);

    }
    // this.advance_amount = (this.totItemprice - this.totItemDisc + this.totVatAmnt)||0;
    //this.advance_amount =0;
  }

  discountShareToItems(){
    
    this.discountMessage = true;
    let gttl = this.totItempriceWithoutBillDisc - this.totItemDiscWithoutBillDisc + this.totVatAmntWithoutBillDisc;
    
    let InpbillDisc = this.billDiscount || 0;
    this.salesItems.forEach((element,i) => {

     
      let grnd_totl = this.salesItems[i].grnd_totl_WithoutBillDisc;
      let billDiscPrec = grnd_totl * 100/ gttl;

      let billDiscountAmt = (InpbillDisc/100 * billDiscPrec);

      
          this.salesItems[i].item_bill_disc =  (this.salesItems[i].rate * this.salesItems[i].salesub_qty) - (((grnd_totl  - billDiscountAmt) * 100) / (this.salesItems[i].salesub_tax_per + 100));
         
      this.calcRates(i,true);
      this.discountMessage = false;
      
    });

  }



  validateSelectedGodown(){
    this.valErrors = {};
    
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = {msg : 'Required'}
      return false;
    } else{
      return true;
    }
  }

  validateBarcode(){
    this.valErrors = {};
    if (!this.barcode || this.barcode === "" || this.barcode === undefined) {
      // console.log('barcode error');
      this.valErrors.barcode = {msg : 'Enter Barcode'}
      return false;
    } else{
      return true;
    }
  }

  godonwChange(){
    delete this.valErrors.gd_id;
    this.resetProdForm();
  }

  resetProdForm(){


  }

  updateDate(cust) {
    if (cust) {

      let date = new Date(this.saleqt_date);

      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }

  selectTaxType(ptypeid) {
    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }

    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      // this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);

      // if (this.taxListCategories.length > 0) {
      //   this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      //   this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      // }
      this.zeroType = true;
    } else {
      this.zeroType = false;

    }
    this.discountShareToItems();
  }
  vaidateForm(){

    this.valErrors = {};

    let retVal = true;
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.valErrors.gd_id = {msg : 'Required'}
    
      retVal =  false;
    }
    if (this.sale_pay_type == 2 || this.sale_pay_type == 4){
      if (this.sale_acc_ledger_id === "" || this.sale_acc_ledger_id === undefined || this.sale_acc_ledger_id === null || this.sale_acc_ledger_id == "0") {
        this.valErrors.sale_acc_ledger_id = {msg : 'Required'}
        retVal =  false;
      }
    }
    if (this.sale_pay_type == 3 || this.sale_pay_type == 4){
      if (this.sale_bank_ledger_id === "" || this.sale_bank_ledger_id === undefined || this.sale_bank_ledger_id === null || this.sale_bank_ledger_id == "0") {
        this.valErrors.sale_bank_ledger_id = {msg : 'Required'}
        retVal =  false;
      }
    }
    if(this.inv_type==1 && (this.vat_no == '' || this.vat_no == null)){
      this.valErrors.vat_no = {msg : 'Required'}
        retVal =  false;
    }
    this.salesItems.forEach((element, i) => {
      
      console.log("stock_qty2222222222");
      console.log(element.stock_qty);


      console.log("unit_available_stock2222222222");
      console.log(element.unit_available_stock);

      console.log("salesub_qty2222222222");
      console.log(element.salesub_qty);

      console.log("sb_id");
      console.log(element.sb_id);



      if(this.checkBatch && element.batches.length >0 && element.sb_id ==undefined || this.checkBatch && element.batches.length >0 && element.sb_id == 0){
        this.valErrors['batch_' + i] = {msg : 'Required'}
        retVal = false;
        alert("Please Select Batch..");
  
      }



      if(element.salesub_prd == ''){
        this.valErrors['salesub_prd_' + i] = {msg : 'Required'}
        retVal =  false;
      } else{
        if(element.salesub_qty == '' || element.salesub_qty <= 0){
          this.valErrors['salesub_qty_' + i] = {msg : 'Required'}
          retVal =  false;
        }
       
        if((element.unit_available_stock<=0 && this.disable_sales_without_stock) || (this.disable_sales_without_stock && (element.unit_available_stock < element.salesub_qty))){
          alert("Sales without stock is disabled");
          this.valErrors['salesub_qty_' + i] = {msg : 'Required'}
          retVal =  false;
        }

        
        else if(
          (element.unit_available_stock <= 0 && this.disable_sales_without_stock) ||
          (this.disable_sales_without_stock &&
            element.unit_available_stock < element.salesub_qty)
        ) {
          alert("Sales without stock is disabled");
          this.valErrors["salesub_qty_" + i] = { msg: "Sales without stock" };
          retVal = false;
        }

        if(element.rate == ''){
          this.valErrors['rate_' + i] = {msg : 'Required'}
          retVal =  false;
        }
        if(this.enable_sale_below_purch && (this.bs_prate > element.rate )){
          alert("Sales below Purchase Rate is disabled");
          this.valErrors['rate_' + i] = {msg : 'Required'}
          retVal =  false;
        }

      }
    });
   

    return retVal;
  }

  addSales(print=false,id) {

    this.button_show=true;
    this.importLoader = false;
    this.editMessage = false;


    this.printError = [];
    if (this.cust_id == null  || this.cust_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (!this.phone_no || this.phone_no == null  || this.phone_no == undefined) {
      this.errObjArr.phoneErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.phoneErr = "f";
    }

    if (this.advance_amount > this.totItemprice - this.totItemDisc + this.totVatAmnt) {
      this.errObjArr.advanceErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.advanceErr = "f";
    }
    this.validationMsg = '';
    if(this.vaidateForm()){
      const searchval = {
        'sq_inv_no' :id,
        'saleqt_date': this.saleqt_date,
        'valid_till_date': this.valid_till_date,
        'sq_total': this.totItemprice - this.totItemDisc + this.totVatAmnt,
        'sq_total_item_disc': this.totItemDisc,
        'sq_disc': 0,
        'sq_tax': this.totVatAmnt,
        'sale_items': this.salesItems,
        'terms': this.terms,
        'sales_note': this.sales_note,
        'sale_agent': this.sale_agent,
        'sales_tax_type': this.purchtypesel - 1,
        'sale_pay_type': this.sale_pay_type,
        'cust_type': ((this.cust_id && this.cust_id.cust_id) ? '2': '1'),
        'cust_id': this.cust_id,
        'sale_acc_ledger_id': (this.sale_pay_type == 2) ? this.sale_acc_ledger_id : this.sale_bank_ledger_id,
        'inv_type': this.inv_type,
        // 'cust_name': (this.cust_id) ? this.cust_id.name : 'Cash Customer',
        'cust_name': (this.cust_id) ? this.cust_id.name : '',
        'cnvert_ref_no': (this.qt_no) ? this.qt_no : '',
        'shipping_type': this.shipping_type,
        'phone_no': this.phone_no ? this.phone_no : '',
        'vat_no': this.vat_no ? this.vat_no : '',
        'delivery_address': this.delivery_address,
        'address': this.address,
        'advance_amount': this.advance_amount,
        'balance_amount' : this.totItemprice - this.totItemDisc + this.totVatAmnt - this.advance_amount,
        // 'advance_amount': this.advance_amount,
      }
      // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
      //   this.coreService.showMessage('Quick Sales Temporarily Blocked');
      //   return false;
      // }

      this.pageLoadingImg = true;


      this.apiService.convertToSales(searchval).subscribe((res) => {

        this.importLoader = false;
    this.editMessage = false;
    this.convertMessage = false;

        // if (res.error != null) {
        //   this.resultobj = res.error;
        //   this.pageLoadingImg = false;
        //   for (var value in this.resultobj) {
        //     this.validationMsg += this.resultobj[value].toString() + '<br>';
        //   }
        // }
        if (res.message) {
          this.router.navigate(['/quick-sales/' + res.message.preview.sales_inv_no]);
        
        
          this.sq_inv_no = '';
          this.pageLoadingImg = false;
          this.cust_id = null
          this.valid_till_date = null;
          this.vat_no = '';
          this.phone_no = '';
          // this.shipping_type = '';
          this.delivery_address = '';
          this.advance_amount = 0;
          this.address = '';
          this.order_no = '';
          this.salesItems = [{
            salesub_prd:'',
            salesub_qty:null,
            base_qty:null,
            salesub_rate:null,
            salesub_unit_id:'',
            salesub_tax_per:null,
            prd_tax_cat_id:null,
            salesub_tax:'',
            item_desc:'',
            Item_descp:'',
            salesub_gd_id:'',
            prod_units: [],
            measurement2:'',
            measurement1:'',
            measurement3:'',
            measurement4:'',
            measurement5:'',
            measurement6:'',
            measurement7:'',
            unit: '',
            // qty: null,
            loadingImg: false,
            rate: null,
            disc_amnt: 0,
            item_disc_type:null,
            excl_vat:1,
            taxpercentage:null,
            itemRate: null,
            grnd_totl: null,
            base_unit_rate: null,
            item_disc: 0,
            item_bill_disc: 0,
            barcode:'',
            stock_qty:0,
            batch_ex_date:null,
          }];
          this.listQtn(1);
          this.nextRefNo();
          this.sumSaleTotal();
          this.apiService.updateSalesOrderNotification();
          // setTimeout(() => {
          //   this.selPriduct.last.focus();
          // }, 500);
        }
      });
      this.listing = true;
      this.importLoader = false;
    this.editMessage = false;
    }
  }

  
  addSalesOrder(print=false) {
    this.button_show=true;
    this.importLoader = false;
    this.editMessage = false;

    this.printError = [];
    if (this.cust_id == null  || this.cust_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (!this.phone_no || this.phone_no == null  || this.phone_no == undefined) {
      this.errObjArr.phoneErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.phoneErr = "f";
    }

    if (this.advance_amount > this.totItemprice - this.totItemDisc + this.totVatAmnt) {
      this.errObjArr.advanceErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.advanceErr = "f";
    }
    this.validationMsg = '';
    if(this.printError.length <= 0){
      this.loading=true;

    if(this.vaidateForm()){

      this.saleqt_date=(this.saleqt_date) ?this.apiService.formatDate(this.saleqt_date) : this.saleqt_date;
      this.valid_till_date=(this.valid_till_date) ?this.apiService.formatDate(this.valid_till_date) : this.valid_till_date;
      const searchval = {

        'saleqt_date': this.saleqt_date,
        'valid_till_date': this.valid_till_date,
        'sq_total': this.totItemprice - this.totItemDisc + this.totVatAmnt,
        'sq_total_item_disc': this.totItemDisc,
        'sq_disc': 0,
        'sq_tax': this.totVatAmnt,
        'sale_items': this.salesItems,
        'terms': this.so_terms,
        'sales_note': this.sales_note,
        'sale_agent': this.sale_agent,
        'sales_tax_type': this.purchtypesel - 1,
        'sale_pay_type': this.sale_pay_type,
        'cust_type': ((this.cust_id && this.cust_id.cust_id) ? '2': '1'),
        'cust_id': this.cust_id,
        'sale_acc_ledger_id': (this.sale_pay_type == 2) ? this.sale_acc_ledger_id : this.sale_bank_ledger_id,
        'inv_type': this.inv_type,
        // 'cust_name': (this.cust_id) ? this.cust_id.name : 'Cash Customer',
        'cust_name': (this.cust_id) ? this.cust_id.name : '',
        'shipping_type': this.shipping_type,
        'phone_no': this.phone_no ? this.phone_no : '',
        'vat_no': this.vat_no ? this.vat_no : '',
        'reference': this.reference,
        'delivery_address': this.delivery_address,
        'address': this.address,
        'advance_amount': this.advance_amount,
        'balance_amount' : this.totItemprice - this.totItemDisc + this.totVatAmnt - this.advance_amount,
        // 'advance_amount': this.advance_amount,
      }
      // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
      //   this.coreService.showMessage('Quick Sales Temporarily Blocked');
      //   return false;
      // }

      this.pageLoadingImg = true;


      this.apiService.addSalesOrder(searchval).subscribe((res) => {

        this.importLoader = false;
        this.editMessage = false;
        if (res.error != null) {
          this.resultobj = res.error;
          this.pageLoadingImg = false;
          for (var value in this.resultobj) {
            this.validationMsg += this.resultobj[value].toString() + '<br>';
          }
        }
        if (res.message) {
         

          if(print){
           
            this.saleQtnData = res.message['preview'];
            this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
            this.previewLoader = false;
            this.qr_inv = this.saleQtnData['qr_link'];
            $('#printviewBtn').click();
          } else{
            this.coreService.showMessage(res.message['msg']);
          }
          this.sq_inv_no = '';
          this.pageLoadingImg = false;
          this.cust_id = null
          this.valid_till_date = null;
          this.vat_no = '';
          this.reference = null
          this.phone_no = '';
          // this.shipping_type = '';
          this.delivery_address = '';
          this.advance_amount = 0;
          this.address = '';
          this.order_no = '';
          this.salesItems = [{
            salesub_prd:'',
            salesub_qty:null,
            base_qty:null,
            salesub_rate:null,
            salesub_unit_id:'',
            salesub_tax_per:null,
            prd_tax_cat_id:null,
            salesub_tax:'',
            item_desc:'',
            Item_descp:'',
            salesub_gd_id:'',
            prod_units: [],
            measurement2:'',
            measurement1:'',
            measurement3:'',
            measurement4:'',
            measurement5:'',
            measurement6:'',
            measurement7:'',
            unit: '',
            // qty: null,
            loadingImg: false,
            rate: null,
            disc_amnt: 0,
            item_disc_type:null,
            excl_vat:1,
            taxpercentage:null,
            itemRate: null,
            grnd_totl: null,
            base_unit_rate: null,
            item_disc: 0,
            item_bill_disc: 0,
            barcode:'',
            stock_qty:0,
            batch_ex_date:null,
          }];
          this.listQtn(1);
          this.nextRefNo();
          this.sumSaleTotal();
          this.apiService.updateSalesOrderNotification();
          setTimeout(() => {
           // this.selPriduct.last.focus();
          }, 500);
          this.clearForm();
          
          this.salesItems =[]; 
        }
      });
      this.importLoader = false;
    this.editMessage = false;
    this.listing = true;
    }
    this.loading=false;

   }

  }


  updateSalesOrder(print=false,id) {
    this.button_show=true;
    this.importLoader = false;
     this.editMessage = true;

     

    this.printError = [];
    if (this.cust_id == null  || this.cust_id == undefined) {
      this.errObjArr.custErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.custErr = "f";
    }

    if (!this.phone_no || this.phone_no == null  || this.phone_no == undefined) {
      this.errObjArr.phoneErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.phoneErr = "f";
    }

    if (this.advance_amount > this.totItemprice - this.totItemDisc + this.totVatAmnt) {
      this.errObjArr.advanceErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });

    } else {
      this.errObjArr.advanceErr = "f";
    }

    this.validationMsg = '';
    if(this.printError.length <= 0){
    if(this.vaidateForm()){
      this.saleqt_date=(this.saleqt_date) ?this.apiService.formatDate(this.saleqt_date) : this.saleqt_date;
      this.valid_till_date=(this.valid_till_date) ?this.apiService.formatDate(this.valid_till_date) : this.valid_till_date;
      const searchval = {
        'sq_inv_no' :id,
        'saleqt_date': this.saleqt_date,
        'valid_till_date': this.valid_till_date,
        'sq_total': this.totItemprice - this.totItemDisc + this.totVatAmnt,
        'sq_total_item_disc': this.totItemDisc,
        'sq_disc': 0,
        'sq_tax': this.totVatAmnt,
        'sale_items': this.salesItems,
        'terms': this.so_terms,
        'sales_note': this.sales_note,
        'sale_agent': this.sale_agent,
        'sales_tax_type': this.purchtypesel - 1,
        'sale_pay_type': this.sale_pay_type,
        'cust_type': ((this.cust_id && this.cust_id.cust_id) ? '2': '1'),
        'cust_id': this.cust_id,
        'sale_acc_ledger_id': (this.sale_pay_type == 2) ? this.sale_acc_ledger_id : this.sale_bank_ledger_id,
        'inv_type': this.inv_type,
        // 'cust_name': (this.cust_id) ? this.cust_id.name : 'Cash Customer',
        'cust_name': (this.cust_id) ? this.cust_id.name : '',
        'shipping_type': this.shipping_type,
        'phone_no': this.phone_no ? this.phone_no : '',
        'vat_no': this.vat_no ? this.vat_no : '',
        'reference': this.reference,
        'delivery_address': this.delivery_address,
        'address': this.address,
        'advance_amount': this.advance_amount,
        'balance_amount' : this.totItemprice - this.totItemDisc + this.totVatAmnt - this.advance_amount,
        // 'advance_amount': this.advance_amount,
      }
      // if(this.cmpny != 'FJK' && this.cmpny != 'FJKIN'){
      //   this.coreService.showMessage('Quick Sales Temporarily Blocked');
      //   return false;
      // }

      this.pageLoadingImg = true;


      this.apiService.updateSalesOrder(searchval).subscribe((res) => {

    //     this.importLoader = false;
    // this.editMessage = false;
        if (res.error != null) {
          this.resultobj = res.error;
          this.pageLoadingImg = false;
          for (var value in this.resultobj) {
            this.validationMsg += this.resultobj[value].toString() + '<br>';
          }
        }
        if (res.message) {
        
          if(print){
      
            this.saleQtnData = res.message['preview'];
            this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
            this.previewLoader = false;
            this.qr_inv = this.saleQtnData['qr_link'];
            $('#printviewBtn').click();
          } else{
            this.coreService.showMessage(res.message['msg']);
          }
          this.sq_inv_no = '';
          this.pageLoadingImg = false;
          this.cust_id = null
          this.valid_till_date = null;
          this.vat_no = '';
          this.reference = null
          this.phone_no = '';
          // this.shipping_type = '';
          this.delivery_address = '';
          this.advance_amount = 0;
          this.address = '';
          this.order_no = '';
          this.salesItems = [{
            salesub_prd:'',
            salesub_qty:null,
            base_qty:null,
            salesub_rate:null,
            salesub_unit_id:'',
            salesub_tax_per:null,
            prd_tax_cat_id:null,
            salesub_tax:'',
            item_desc:'',
            Item_descp:'',
            salesub_gd_id:'',
            prod_units: [],
            measurement2:'',
            measurement1:'',
            measurement3:'',
            measurement4:'',
            measurement5:'',
            measurement6:'',
            measurement7:'',
            unit: '',
            // qty: null,
            loadingImg: false,
            rate: null,
            disc_amnt: 0,
            item_disc_type:null,
            excl_vat:1,
            taxpercentage:null,
            itemRate: null,
            grnd_totl: null,
            base_unit_rate: null,
            item_disc: 0,
            item_bill_disc: 0,
            barcode:'',
            stock_qty:0,
            batch_ex_date:null,
          }];
          this.listQtn(1);
          this.nextRefNo();
          this.sumSaleTotal();
          setTimeout(() => {
            this.selPriduct.last.focus();
          }, 500);

          this.clearForm();
          this.salesItems =[]; 
        }
      });
      this.listing=true;
      this.importLoader = false;
      this.editMessage = false;

    }
   }

  }

  previewSalesOrder(id) {
    let searchval = new FormData();
    searchval.append("sales_odr_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesOrderInvPreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.saleQtnData = res.data['preview'];
        this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
        this.previewLoader = false;

        this.qr_inv = this.saleQtnData['qr_link'];
      }
    });
  }

  proformaInvoice(id,type='') {
    let searchval = new FormData();
    searchval.append("sales_odr_inv_no", id);
    this.previewLoader = true;
    this.apiService.getSalesOrderInvPreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.saleQtnData = res.data['preview'];
        this.saleQtnData.proforma_inv='PROFORMA INVOICE';
        this.saleQtnData.type=type;
        this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
        this.previewLoader = false;

        this.qr_inv = this.saleQtnData['qr_link'];
      }
    });
  }

  converToSales(id) {
    this.listing=false;
    this.convertMessage = true;
    this.hide_rate_colmn=true;
    this.button_show=false;
    let searchval = new FormData();
    searchval.append("sales_odr_inv_no", id);
    this.importLoader = true;
    this.apiService.getSalesOrderDetails(searchval).subscribe((res: any) => {
    
      // if (res.data) {

      //   this.saleQtnData = res.data['preview'];
      //   this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
      //   this.previewLoader = false;

      //   this.qr_inv = this.saleQtnData['qr_link'];
      // }


      this.pageLoadingImg = false;
      this.cust_id =res.data.customer;
      this.valid_till_date = null;
      this.vat_no = res.data.sales_odr_cust_tin;
      this.phone_no = res.data.sales_odr_cust_ph;
      this.sq_inv_no = res.data.sales_odr_inv_no;
      this.sales_note = res.data.sales_odr_notes;

      // if (res.data.sales_odr_due_date) {
      //   this.valid_till_date = new Date(res.data.sales_odr_due_date);
      // } else {
      //   this.valid_till_date = '';
      // }
      // this.saleqt_date = new Date(res.data.sales_odr_date);
      this.valid_till_date = '';
      this.saleqt_date = new Date();


      if (res.data.sales_odr_shipping_type == 1) {
           this.shipping_type = 1;
      } else if (res.data.sales_odr_shipping_type == 2) {
        this.shipping_type = 2;
      } else if (res.data.sales_odr_shipping_type == 3) {
        this.shipping_type = 3;
      }

      // if (res.data.sales_odr_pay_type == 0) {
      //   this.sale_pay_type = 2;
      // } else if (res.data.sales_odr_pay_type == 1) {
      //   this.sale_pay_type = 1;
      // } else if (res.data.sales_odr_pay_type == 2) {
      //   this.sale_pay_type = 3;
      // }

      this.sale_pay_type = 1;
      this.salesPayType = [
        { id: 1, name: 'Credit' },

      ];


      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.cashLedgers = res.data.acc_ledger;
        this.sale_acc_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.bankLedgers = res.data.acc_ledger;
        this.sale_bank_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      // this.shipping_type = '';
      this.delivery_address = res.data.sales_odr_dlvry_addrs;
      this.advance_amount = res.data.sales_odr_advance_amount;
      this.address = res.data.sales_odr_cust_address;
      this.order_no = '';
      this.billDiscount =res.data.sales_odr_discount;
      this.salesItems = [];
      var ids = res.data.sales_order_sub.map(v => (v.salesub_prd));
      this.stkprd_all = [];
      this.searchStkPrdForEdit(ids);
      var myInterval = setInterval(()=> {
         
         if(this.stkprd_all.length > 0){
          this.qt_no =res.data.sales_odr_branch_inv;
          this.nextRefNo1();
           clearInterval(myInterval);

           this.editItemLoading(res);
         }
      }, 500);

    });

    // this.importLoader = false;
    // this.editMessage = false;
    //this.convertMessage = false;
  }

  editSalesOrder(id) {

      console.log("fffffffffffffffffff");
      console.log(id);

    this.editMessage = true;
    this.button_show=false;
    let searchval = new FormData();
    searchval.append("sales_odr_inv_no", id);
    this.importLoader = true;
    this.apiService.getSalesOrderDetailsAll(searchval).subscribe((res: any) => {
     
      // if (res.data) {

      //   this.saleQtnData = res.data['preview'];
      //   this.is_prew_description = this.saleQtnData['sales_order_sub'].filter(x => x.item_descrp);
      //   this.previewLoader = false;

      //   this.qr_inv = this.saleQtnData['qr_link'];
      // }
     
      console.log("test1");
      console.log(res);
      this.pageLoadingImg = false;
      this.cust_id =res.data.customer;
      this.valid_till_date = null;
      this.vat_no = res.data.sales_odr_cust_tin;
      this.reference = res.data.sales_odr_reference;
      this.phone_no = res.data.sales_odr_cust_ph;
      this.sq_inv_no = res.data.sales_odr_inv_no;
      this.sales_note = res.data.sales_odr_notes;
      this.so_terms = res.data.sales_odr_terms;
      this.sale_agent = res.data.sales_odr_agent_ledger_id;

      if (res.data.sales_odr_due_date) {
        this.valid_till_date = new Date(res.data.sales_odr_due_date);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sales_odr_date);


      if (res.data.sales_odr_shipping_type == 1) {
           this.shipping_type = 1;
      } else if (res.data.sales_odr_shipping_type == 2) {
        this.shipping_type = 2;
      } else if (res.data.sales_odr_shipping_type == 3) {
        this.shipping_type = 3;
      }

      if (res.data.sales_odr_pay_type == 0) {
        this.sale_pay_type = 2;
      } else if (res.data.sales_odr_pay_type == 1) {
        this.sale_pay_type = 1;
      } else if (res.data.sales_odr_pay_type == 2) {
        this.sale_pay_type = 3;
      }


      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.cashLedgers = res.data.acc_ledger;
        this.sale_acc_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.bankLedgers = res.data.acc_ledger;
        this.sale_bank_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      // this.shipping_type = '';
      this.delivery_address = res.data.sales_odr_dlvry_addrs;
      this.advance_amount = res.data.sales_odr_advance_amount;
      this.address = res.data.sales_odr_cust_address;
      this.order_no = '';
      this.billDiscount =res.data.sales_odr_discount;
      this.salesItems = [];

   
     var ids = res.data.sales_order_sub.map(v => (v.salesub_prd));

     console.log("edittest");
     console.log(ids);

  
      this.stkprd_all = [];
      this.searchStkPrdForEdit(ids);
      this.nextRefNo1();
      this.apiService.updateSalesOrderNotification();
      var myInterval = setInterval(()=> {
         
         if(this.stkprd_all.length > 0){
          this.qt_no =res.data.sales_odr_branch_inv;
           clearInterval(myInterval);
          
           this.editItemLoading(res);
         }
      }, 1000);

    });

    // this.importLoader = false;
    // this.editMessage = false;
  }

editItemLoading(res){

  console.log("reeeeeeeeeeeeeeerrrrrrrr");
  console.log(res);
 
  var tmp ={};
 res.data.sales_order_sub.forEach((element,i) => {

  console.log("element['base_qty']");
  console.log(element['base_qty']);
 
  let selProd = this.stkprd_all.find(element1 => element1.bs_prd_id == element['salesub_prd']);
  var tmp = {
      salesub_prd:selProd,
      salesub_qty:element['salesub_qty'],
      rate: element['rate'],
      salesub_tax_amnt: element['salesub_tax_amnt'],
      base_qty:element['base_qty'],
      salesub_rate: element['rate'],
      salesub_unit_id:[],
      salesub_tax_per:element['sales_ord_sub_tax_per'],
      prd_tax_cat_id:element['prd_tax_cat_id'],
      salesub_tax:element['sales_ord_sub_tax'],
      item_desc:element['item_desc'],
      Item_descp:element['Item_descp'],
      salesub_gd_id:element['sales_ord_sub_gd_id'],
      prod_units: [],
      measurement2:element.measurement2,
      measurement1:element['measurement1'],
      measurement3:element['measurement3'],
      measurement4:element['measurement4'],
      measurement5:element['measurement5'],
      measurement6:element['measurement6'],
      measurement7:element['measurement7'],
      sb_id:element['sb_id'],
      unit: '',
      // qty: null,
      loadingImg: false,

      disc_amnt: element['item_disc'],
      item_disc_type:element['item_disc_type'],
      excl_vat:1,
      taxpercentage:null,
      itemRate: null,
      grnd_totl: element['salesub_qty'] * element['rate'] + element['salesub_tax_amnt'],
      base_unit_rate: null,
      item_disc: element['item_disc'],
      item_bill_disc: 0,
      barcode:'',
      stock_qty:0,
      batch_ex_date:null,
       grnd_totl_WithoutBillDisc :element['sales_ord_sub_amnt'] + element['salesub_tax_amnt'],
      salesub_rate_without_bill_disc : element['rate'],
      item_disc_WithoutBillDisc : element['item_disc'],
      salesub_tax_amnt_WithoutBillDisc : element['salesub_tax_amnt'],
      
    
    }
    
    this.salesItems.push(tmp);


    //  this.selectedProduct(i);

       

    this.salesItems[i].prod_units = element['prod'].prod_units;
    this.salesItems[i].salesub_unit_id = element['salesub_unit_id'];
    this.salesItems[i].loadingImg = false;
    this.salesItems[i].batches = element['prod'].batches;


      this.salesItems[i].unit_available_stock= element['prod'].stock_qty;
      this.salesItems[i].available_stock= element['prod'].stock_qty;
      this.bs_prate=element['prod'].bs_prate;
      this.unit_bs_prate=element['prod'].bs_prate;
      // salesub_unit_id
      // current stock of product, actully chk on the time of save for accurate result
      this.salesItems[i].stock_qty = element['prod'].stock_qty;



    // var myInterval1 = setInterval(()=> {
        if(this.salesItems[i].prod_units.length > 0){
          this.qt_no =res.data.sales_odr_branch_inv;
          // this.nextRefNo1();
          // clearInterval(myInterval1);
          
          this.salesItems[i].sb_id = element['sb_id'];
          this.salesItems[i].salesub_unit_id = element['salesub_unit_id'];
          this.salesItems[i].salesub_qty = element['salesub_qty'];
          this.salesItems[i].salesub_rate = element['rate'];
          this.salesItems[i].Item_descp = element['item_desc'];
          this.salesItems[i].rate = element['rate'];
          this.salesItems[i].base_qty = element['base_qty'];
          this.salesItems[i].salesub_prd_id =element['sales_ord_sub_prod_id'];

          if(element['sb_id'] > 0)
          {
            this.updateStock(i);
            // this.batchStock(i);
          }
          this.calcRates(i);
          this.sumSaleTotal();
        }

    //     console.log("  this.salesItems[i].base_qty = element['base_qty'];11111111");
    //     console.log(this.salesItems[i].base_qty = element['base_qty']);
    // }, 500);


    // this.searchStkPrd(element['prd_name'], this.salesItems.length -1);
    // this.getStockDetails(this.salesItems.length -1,false);
});

this.listQtn(1);
this.sumSaleTotal();

this.listing = false;
this.importLoader = false;

// this.editMessage = false;
// setTimeout(() => {
//   this.selPriduct.last.focus();
// }, 500);
  }

  showList(list) {
    if(list){
      this.listing=false;
    }else{
      this.listing=true;
    }
    this.clearForm();



  }

  searchInp(keyword) {
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.searchLoadingImg = true;
    this.apiService.getSalesOrderList(searchval, 1).subscribe((res) => {
      this.list_sales = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
    });
  }

  listQtn(pageNo = 1, keyword='') {
    let searchval = new FormData();
    if(keyword != '')
    searchval.append("keyword", keyword);
    this.searchLoadingImg = true;
    this.apiService.getSalesOrderList(searchval, pageNo).subscribe((res) => {
      this.list_sales = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.searchLoadingImg = false;
      // console.log(res);
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  goBack(){
    this.listing=true;
    this.editMessage=false;
    this.convertMessage=false;
    this.button_show=true;
    this. clearForm();
    this.copyRef='';
  }

  clearForm(){
    this.cust_id = null
    this.valid_till_date = null;
    this.vat_no = '';
    this.phone_no = '';
    this.sq_inv_no = '';
    // this.shipping_type = '';
    this.delivery_address = '';
    this.advance_amount = 0;
    this.address = '';
    this.order_no = '';
    this.salesItems = [{
      salesub_prd:'',
      salesub_qty:null,
      base_qty:null,
      salesub_rate:null,
      salesub_unit_id:'',
      salesub_tax_per:null,
      prd_tax_cat_id:null,
      salesub_tax:'',
      item_desc:'',
      Item_descp:'',
      salesub_gd_id:'',
      prod_units: [],
      measurement2:'',
      measurement1:'',
      measurement3:'',
      measurement4:'',
      measurement5:'',
      measurement6:'',
      measurement7:'',
      unit: '',
      // qty: null,
      loadingImg: false,
      rate: null,
      disc_amnt: 0,
      item_disc_type:null,
      excl_vat:1,
      taxpercentage:null,
      itemRate: null,
      grnd_totl: null,
      base_unit_rate: null,
      item_disc: 0,
      item_bill_disc: 0,
      barcode:'',
      stock_qty:0,
      batch_ex_date:null,
    }];
    this.nextRefNo();
    this.nextRefNo1();
    this.sumSaleTotal();
    // this.modalRef.close();
    setTimeout(() => {
      this.selPriduct.last.focus();
    }, 500);
  }

  clearForm1(){

    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      is_supplier : false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };

  }


  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = 'createCustOrSup';
    if(this.userType =='ADMIN'){
      this.customer_dt.usr_type = 1;
    }else{
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj1 = res.error;
      } else {
        this.resultobj1 = {};
        this.modalRef.close();
        // this.clearForm();
        this.clearForm1();

      }
    });
  }
  langChange(){
    this.translate.get(['Common.normal', 'Common.cargo', 'Common.pickup']).subscribe((res: string) => {    
     

      this.shipping_types = [
        { id: 1, name: res['Common.normal']},
        { id: 2, name: res['Common.cargo'] },
        { id: 3, name: res['Common.pickup'] }
      ];
    });

    this.translate.get(['Common.standard_rated_domestic', 'Common.zero_rated_domestic', 'Common.exempt_sales']).subscribe((res: string) => {    
      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic'] },
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_sales'] },
      ];
    });
  }

  updateStock(i){
    if(this.salesItems[i].sb_id){
      // this.selctedProd.stock_qty = 333
      // getGodownBatchStock(){}
      const searchval = new FormData();
      searchval.append('prd_id', this.salesItems[i].salesub_prd.prd_id);
      searchval.append('gd_id', this.gd_id);
      searchval.append('price_group_id', this.price_group_id);
      searchval.append('batch_id', this.salesItems[i].sb_id);
      this.batchLoading = true;
      this.apiService.getGodownBatchStock(searchval).subscribe((resp) => {
        this.batchLoading = false;

        // console.log("gd details");
        // console.log(resp['data']['gdStock']);

        // console.log("batch Details");
        // console.log(resp['data']['prod_units']);

        console.log("gd details2");
        console.log(resp['data']['gdStock']);
        // this.salesItems[i].stock_qty = resp['data']['gdStock'].gbs_qty || 0;
       // this.salesItems[i].prod_units =resp['data']['prod_units'];
       if(resp['data']['gdStock']){
        this.salesItems[i].unit_available_stock = resp['data']['gdStock'].gbs_qty || 0;
        this.salesItems[i].stock_qty =resp['data']['gdStock'].gbs_qty || 0;
        this.batchStock(i);
        // this.selectedUnit(i);

       }
       else{
        this.salesItems[i].sb_id =0;
        this.salesItems[i].unit_available_stock =  0;
        this.salesItems[i].stock_qty =0;
         //this.selectedUnit(i);
       }
      
      });
    } else{
      // this.salesItems[i].stock_qty = this.stock_qty[i];
      this.salesItems[i].unit_available_stock = this.salesItems[i].stock_qty;
      
    }
  }

  batchStock(i){
    if(this.salesItems[i].sb_id){
      console.log("ggggggggggggg");
      console.log(this.salesItems[i].sb_id);

      const searchval = new FormData();
      searchval.append('prd_id', this.salesItems[i].salesub_prd.prd_id);
      searchval.append('batch_id', this.salesItems[i].sb_id);
      this.apiService.getBatch(searchval).subscribe((resp) => {

        console.log("hi sssss");
        console.log(resp.sb_expiry_date);
        this.salesItems[i].sb_id =  this.salesItems[i].sb_id;
        this.salesItems[i].batch_ex_date = resp.sb_expiry_date;

        
      });

    }

  }



  copyTransfer(refno){

    const searchval = new FormData();
    searchval.append('sales_odr_inv_no', refno);
  
  this.copyLoading=true;
  
    this.apiService.getsalecopyDetails(searchval).subscribe((res) => {
if(res.data){
  this.listing = false;
  this.copyLoading=false;
      console.log(res);
      this.pageLoadingImg = false;
      this.cust_id =res.data.customer;
      this.valid_till_date = null;
      this.vat_no = res.data.sales_odr_cust_tin;
      this.phone_no = res.data.sales_odr_cust_ph;
      // this.sq_inv_no = res.data.sales_odr_inv_no;
      this.sales_note = res.data.sales_odr_notes;
      this.sale_agent = res.data.sales_odr_agent_ledger_id;
      

      if (res.data.sales_odr_due_date) {
        this.valid_till_date = new Date(res.data.sales_odr_due_date);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sales_odr_date);


      if (res.data.sales_odr_shipping_type == 1) {
           this.shipping_type = 1;
      } else if (res.data.sales_odr_shipping_type == 2) {
        this.shipping_type = 2;
      } else if (res.data.sales_odr_shipping_type == 3) {
        this.shipping_type = 3;
      }

      if (res.data.sales_odr_pay_type == 0) {
        this.sale_pay_type = 2;
      } else if (res.data.sales_odr_pay_type == 1) {
        this.sale_pay_type = 1;
      } else if (res.data.sales_odr_pay_type == 2) {
        this.sale_pay_type = 3;
      }


      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.cashLedgers = res.data.acc_ledger;
        this.sale_acc_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      if (res.data.sales_odr_acc_ledger_id > 0) {
        this.bankLedgers = res.data.acc_ledger;
        this.sale_bank_ledger_id = res.data.sales_odr_acc_ledger_id;
      }

      // this.shipping_type = '';
      this.delivery_address = res.data.sales_odr_dlvry_addrs;
      this.advance_amount = res.data.sales_odr_advance_amount;
      this.address = res.data.sales_odr_cust_address;
      this.order_no = '';
      this.billDiscount =res.data.sales_odr_discount;
      this.salesItems = [];

   
     var ids = res.data.sales_order_sub.map(v => (v.salesub_prd));
  
      this.stkprd_all = [];
      this.searchStkPrdForEdit(ids);
      this.nextRefNo1();
      this.apiService.updateSalesOrderNotification();
      var myInterval = setInterval(()=> {
        this.no_transfr='';
         if(this.stkprd_all.length > 0){
          this.qt_no =res.data.sales_odr_branch_inv;
           clearInterval(myInterval);
          
           this.editItemLoading(res);
         }
      }, 500);
    }else{
       this.copyLoading=false;
       this.no_transfr='Not found. Please check the reference number and try again';
       this. clearForm();
    }
    });
  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {

      // console.log(res['data']);
      if(res['data']){
        this.g_settings = res['data'];
        this.generalSearch_type = this.g_settings.g_s_search_type;
      }else{
        this.generalSearch_type=0;
      }
      
      console.log(this.generalSearch_type);
      
    });

  }

}
