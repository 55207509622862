import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-supplier-report',
  templateUrl: './supplier-report.component.html',
  styleUrls: ['./supplier-report.component.css']
})
export class SupplierReportComponent implements OnInit {
  cur_lang: string;
  supplierAll: string[];
  resultobj: any;
  showBal: boolean;
  accLoading = false;
  pq_supp_type: 0;
  sup_balance: 0;
  sup_bal_sign: '';
  sup_tin: any;
  supp_default_currency_id = 0;
  pageLoadingImg: boolean;
  selecTed: any;
  branch_name: any;
  sup_name: any='';
  country_dec: number=3;
  curpage: number;
  lastpage: number;
  pgstart: boolean;
  pgend: boolean;
  purch_supp_id: any;
  sup_bal: any=0;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.country_dec=this.coreService.setDecimalLength();
  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  selectSupplier(sup_id){
    if(sup_id){
      this.pageLoadingImg = true;
      const searchval = new FormData();
      searchval.append('sup_id', sup_id);
      this.apiService.supplierRep(searchval,1).subscribe((res) => {
        this.pageLoadingImg = false;
      
        this.selecTed = res['data']['data']
       
        this.sup_name=res.sup_name.supp_name;
        this.branch_name=res.branch_name.branch
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
  
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
       
    
       
      });
      this.getSupBalance(sup_id)
    }else{
      this.selecTed = [];
      // this.sup_name=[]
      this.branch_name=[]
      this.sup_bal_sign=''
      this.sup_bal=0;
    }
  


  }
  getSupBalance(sup_id, $exchngeRate = null) {
   
    this.showBal = false;
    this.accLoading = false;
    this.pq_supp_type=0;
    if (sup_id) {
      this.accLoading = true;
      this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
        this.accLoading = false;
        this.sup_balance = res.data.bal_without_sign;
        this.sup_bal = res.data.bal_without_sign;
        this.sup_bal_sign = res.data.bal_sign;
        this.sup_tin = res.data.supp_tin;
        this.supp_default_currency_id = res.data.default_currency_id;
        // if (this.supp_default_currency_id &&  this.multipleCurrency) {
        //   this.selcurrency = this.currencies.find(x => x.cur_id == this.supp_default_currency_id);
        //   if ($exchngeRate == null) {
        //     this.exchange_rate = this.selcurrency.cur_exchange_rate;
        //   } else {
        //     this.exchange_rate = $exchngeRate;
        //   }
        // } else {
        //   this.selcurrency = null;
        //   this.exchange_rate = 1;
        // }
        this.showBal = true;
      });
      // let searchval = new FormData();
      // if(this.purchasenumber){
      //   searchval.append('purchaseno', this.purchasenumber);
      // }
      //  searchval.append('sup_id', sup_id);
      // this.apiService.getSupInvoice(searchval).subscribe((res) => {
      //   this.sup_invoice_no = res.data;
      // });

    }
  }
  pageNext(formdata: { value: any; }, pageno: any) {

      this.pageLoadingImg = true;
       formdata.value.sup_id = this.purch_supp_id;
      this.apiService.supplierRep(formdata.value, pageno).subscribe((res) => {
        this.pageLoadingImg = false;
      
        this.selecTed = res['data']['data']
       
        this.sup_name=res.sup_name.supp_name;
        this.branch_name=res.branch_name.branch
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
  
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
  
    
       
      });
  }
  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


}
